
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        jumpUrl: {
            type: String,
            default: ''
        }
    },
    emits: ['close', 'success'],
    setup(props, { emit }) {
        function jump() {
            window.open(props.jumpUrl);
            emit('success');
        }
        return {
            jump
        };
    }
});
